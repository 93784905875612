import * as React from "react"
import PortfolioHeader from "../../components/PortfolioHeader"
import { StaticImage } from "gatsby-plugin-image"
import { Colors, Padding } from "../../components/Styles"
import { useEffect, useState } from 'react'

const images = [
    <StaticImage
        src="../../images/Portfolio/Esters/img1.png"
        loading="eager"
        quality={95}
        formats={["auto", "webp", "avif"]}
        objectFit="cover"
        alt="" />,
    <StaticImage
        src="../../images/Portfolio/Esters/img2.png"
        loading="eager"
        quality={95}
        formats={["auto", "webp", "avif"]}
        objectFit="cover"
        alt="" />,
    <StaticImage
        src="../../images/Portfolio/Esters/img3.png"
        loading="eager"
        quality={95}
        formats={["auto", "webp", "avif"]}
        objectFit="cover"
        alt="" />,
    <StaticImage
        src="../../images/Portfolio/Esters/img4.png"
        loading="eager"
        quality={95}
        formats={["auto", "webp", "avif"]}
        objectFit="cover"
        alt="" />,
    <StaticImage
        src="../../images/Portfolio/Esters/img5.png"
        loading="eager"
        quality={95}
        formats={["auto", "webp", "avif"]}
        objectFit="cover"
        alt="" />,
    <StaticImage
        src="../../images/Portfolio/Esters/img6.png"
        loading="eager"
        quality={95}
        formats={["auto", "webp", "avif"]}
        objectFit="cover"
        alt="" />
]

const PortfolioPage = () => {
    const [isDesktop, setDesktop] = useState(null)
    useEffect(() => {
        if (typeof window !== 'undefined') {
            setDesktop(window.innerWidth > 920)
        }
    }, [])

    const updateMedia = () => {
        setDesktop(window.innerWidth > 920)
    }

    useEffect(() => {
        window.addEventListener("resize", updateMedia)
        return () => window.removeEventListener("resize", updateMedia)
    })

    if (isDesktop) return (
        <div style={{ backgroundColor: Colors.cream, padding: Padding.gutter }}>
            <PortfolioHeader title={"Esters' Baby Shower"} description="Rose Gold and Blush Color Scheme" />
            <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                {
                    images.map((image) => <div style={{ padding: "10px" }}>{image}</div>)
                }
            </div>
        </div >
    )
    else return (
        <div style={{ backgroundColor: Colors.cream, padding: Padding.mobileGutter, paddingTop: "120px" }}>
            <PortfolioHeader title={"Esters' Baby Shower"} description="Rose Gold and Blush Color Scheme" />
            <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                {
                    images.map((image) => <div style={{ padding: "10px" }}>{image}</div>)
                }
            </div>
        </div >
    )

}

export default PortfolioPage
